@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter' !important;
    text-decoration: none;
    box-sizing: border-box;
}

body{
    background-color: #EEEEEE;
}

ul{
    text-decoration: none;
    list-style: none;
}