a.logoPrincipal{
  height: 110px;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 1000;
}

a.logoPrincipal img{
  height: 100%;
}

.mapWrapper {
  text-align: center;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  padding: 1em;
  margin-top: 6em;
}

.modalBuenaPractica {
  width: auto;
}

.menuFiltrosFloat {
  display: none;
}

.upperSideContainer {
  grid-column: 1 / 5;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tabContainer {
  grid-column: 1 / 5;
  grid-row: 2;
}

.tabulador {
  background-color: white;
  height: 900px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 7px 10px 19px -5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.menuTabulador {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 3.5em;
  justify-content: center;
  align-items: center;
}

.menuTabulador button {
  height: 100%;
  border: none;
  background-color: #00868d;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: white;
}

.menuTabulador button.activate {
  background-color: white;
  color: black;
}

.fichaTecnicaWrapper {
  width: 50vw;
  height: 80vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.5fr 10fr;
  align-items: center;
  justify-content: center;
  row-gap: 0.7em;
}

.fichaTecnica {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.navFiltros {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.encabezadoFiltro {
  font-size: 20px;
  min-height: 35px;
  display: flex;
  padding-left: 0.5em;
}

.filtros {
  max-height: 500px;
}

.scroll {
  overflow: scroll;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 7px;
}

.scrollHorizontal::-webkit-scrollbar {
  height: 7px;
}

.scroll::-webkit-scrollbar-thumb,
.scrollHorizontal::-webkit-scrollbar-thumb {
  background-color: #00868d;
  border-radius: 100vw;
}

.filtro {
  display: flex;
  gap: 1em;
  align-items: flex-start;
}

.buttonsFiltros {
  max-height: 100px;
  display: grid;
  row-gap: 0.5em;
}

.resultsComparador {
  height: 90%;
}

.tablaCell {
  border: solid #eeeeee 1px;
  border-top-width: 0;
  border-bottom-width: 0;
}

.logo {
  width: 50%;
}

.logo img {
  max-width: 80%;
  margin: 0 auto;
  max-height: 30px;
  margin: 0 auto;
}

.mensajeComparador {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  width: 80px; /* el tamaño */
  padding: 15px; /* grosor del borde */
  background: #00868d; /* color */

  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: load 1s linear infinite;
}

.card {
  display: flex;
  flex-shrink: 3;
  width: 100%;
  max-width: 300px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0.5em;
  cursor: pointer;
  transition: all 1s ease;
}

.card:hover {
  transform: scale(1.1);
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}

.body_loader {
  margin: 0;
  display: grid;
  place-content: center;
  height: 100%;
}

.wordCloudMainContainer {
  padding: 50px;
}

.resumeGoodPractices{
  --swiper-navigation-color: #00868D;
  --swiper-pagination-color: #94D4C4;
  height: "100%";
}

@media (max-width: 1024px) {
  .fichaTecnicaWrapper { 
    width: 80vw;
    height: 70vh;
  }
}

@media (max-width: 768px) {
  .mapWrapper {
    grid-template-columns: 1fr;
  }

  .modalBuenaPractica {
    width: 80vw;
  }
  
  .upperSideContainer {
    grid-column: 1 / 5;
    grid-row: 1;
  }
  
  .tabContainer {
    grid-column: 1 / 5;
    grid-row: 2;
  }

  .mapContainer {
    grid-column: 1 / 3;
  }

  .menuFiltrosFloat {
    display: block;
    position: absolute;
    top: 80px;
    right: 80px;
  }
  
  .filtersWrapper {
    display: none;
  }

  .wordCloudMainContainer {
    padding: 10px;
  }

  .resumeGoodPractices{
    --swiper-navigation-color: #f4bc33;
    --swiper-pagination-color: #f4bc33;
    height: "100%";
  }

  a.logoPrincipal{
    height: 70px;
    border-radius: 0;
  }
}

.containerGoodPractices{
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-height: 250px;
}

img.imgBuenaPractica{
  width: 100%;
}


@media (max-width: 375px) {
  .logo {
    width: 0;
  }

  img.imgBuenaPractica{
    width: 50px;
  }
  
  .explicacionBuenaPractica{
    max-height: 150px;
  }

  .containerGoodPractices{
    max-height: 150px;
  }
}

@media (max-width: 425px){
  img.imgBuenaPractica{
    width: 50px;
  }
  
  .explicacionBuenaPractica{
    max-height: 150px;
  }

  .containerGoodPractices{
    max-height: 150px;
  }
}

@media (max-height: 890px) {
  .filtros {
    max-height: 450px;
  }
}