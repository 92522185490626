.subTemaPrincipalWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}

.lineaDiagrama{
  position: absolute;
  width: 88%;
  height: 0.2em;
  background-color: #f4bc33;
  top: -1em;
}

.lineaDiagramaTema{
  position: absolute;
  height: 4em;
  width: 1em;
  background-color: #f4bc33;
  top: -4em;
}

.lineaDiagramaTemaBottom{
  position: absolute;
  height: 1.7em;
  width: 0.3em;
  background-color: #f4bc33;
  top: -2.5em;
  right: 2.78em;
}

a.subTemaPrincipalSlide {
  font-size: 10px;
  background-color: #51708D;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  color: white;
  cursor: pointer;
}

.subIndicacion {
  font-size: 1rem;
}

.mapa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
}

.mapa .titulo {
  font-size: 20px;
  font-weight: bold;
}

.mapa .indicacion {
  font-size: 14px;
  text-align: initial;
}

.slides {
  width: 100vh;
}

@media (max-width: 425px) {
  .subTemaPrincipalSlide {
    display: none;
  }
}

a.casoEstudio {
  min-width: 10vw;
  max-width: 15vw;
  height: 6vh;
  max-height: 12vh;
  font-size: 10px;
  background-color: #08b1a9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  padding: 5px;
}

a.casoEstudio:hover {
  background-color: #f4bc33;
  color: black
}

@media (max-width: 425px) {
  .slides {
    transform: translate(-50%, -80%) scale(0.320) !important;
  }
}

.MapaDiagramaInteractivo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
}

.proceso {
  position: relative;
}

.link {
  position: absolute;
  top: 10%;
  right: 0;
  transform: translateY(-50%);
}

.faseProceso {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

a.encabezado {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: #5AA8AA;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  margin: 0 auto;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 0 1em;
}

a.encabezado:hover {
  color: white;
}

a.subtitulo-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #4C7682;
  border-radius: 50%;
  margin: 0 auto;
  color: white;
  text-align: center;
  font-size: 10px;
  padding: 0 1em;
  transition: all ease 500ms;
}

a.subtitulo-1:hover {
  background: #fab226;
  color: white;
  font-weight: bold;
  font-size: 11px;
  transform: scale(1.1);
}

a.subtitulo-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  background: #4C7682;
  border-radius: 50%;
  font-weight: bold;
  margin: 0 auto;
  color: white;
  text-align: center;
  font-size: 10px;
  padding: 0 1em;
  transition: all ease 1ms;
}

a.subtitulo-2:hover {
  background: #fab226;
  color: white;
  font-weight: bold;
  font-size: 12px;
  transform: scale(1.1);
}

a.casosDeEstudioButton {
  background-color: #153F7B;
  width: 50%;
  margin: 0 auto;
  font-size: clamp(0.7rem, 1.1rem, 2rem);
  height: 80px;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: all ease 500ms;
}

a.casosDeEstudioButton:hover {
  background: #fab226;
  color: white;
  font-weight: bold;
}

a .numFase {
  position: absolute;
  top: 0;
  left: -30px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 134, 141, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.3rem;
  font-weight: bold;
  z-index: 100;
}

.slide-Diagrama {
  width: 100%;
  max-height: 600px;
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
}

.house {
  position: absolute;
  top: -1.5em;
  right: 2.5em;
}

.subtemas {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.header {
  width: 100%;
  padding-left: 1.5em;
  display: flex;
}

.header img{
  max-width: 150px;
}

.encabezados {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 1rem;
}

.encabezados .title,
h2.title-2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0 10px;
}

.encabezados .subTitle {
  font-size: 16px;
  background: #fab226;
  padding: 1em 2em;
  border-radius: 35px;
  color: #000;
}

.encabezados .subTitle span {
  font-weight: bold;
  color: white;
  margin-right: 1em;
}

.cuerpo,
.cuerpo-2 {
  font-size: 16px;
  text-align: justify;
  margin: 1.5em 0;
}

.listCuerpo{
  margin-top: 2em;
  display: grid;
  align-items: center;
  gap: 15px;
}

.listCuerpo.icon{
  grid-template-columns: 1fr 3fr;
}

.listCuerpo img{
  margin: 0 auto;
}

.listCuerpo ul li{
  margin-bottom: 1em;
}

.list-cards {
  margin-top: 1em;
  display: flex;
  gap: 20px;
  margin-bottom: 1em;
}

.list-cards-1 {
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  row-gap: 30px;
}

.list-cards-2 {
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  row-gap: 30px;
}

.card-Mapa {
  position: relative;
  width: 90%;
  padding: 1em 1em;
  padding-bottom: 2em;
  margin: 0 auto;
  background: #c7d0d8;
  font-size: 12px;
  color: #000;
  text-align: justify;
  display: flex;
  flex-direction: column;
}

.card-Mapa span {
  position: absolute;
  top: -20px;
  left: -15px;
  background: #00868d;
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-Mapa .encabezado-2 {
  position: absolute;
  top: -13px;
  left: 1.5em;
  background: #00868d;
  color: white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}

.div-2-izq,
.div-3-izq {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.div-2-der,
.div-3-der {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
  text-align: right;
  justify-content: center;
  align-items: center;
}

.div-3-der {
  grid-template-columns: 3fr 1fr 1fr;
}
.div-3-izq {
  grid-template-columns: 1fr 1fr 3fr;
}

.div-4 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.div-4 h1,
.div-4 p,
.div-4 div {
  background-color: #c7d0d8;
  height: 100%;
  position: relative;
  top: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 1em;
}

.div-4 p {
  text-align: justify;
  padding: 0 1em;
}

.mensaje {
  display: flex;
}

.mensaje img {
  width: 50px;
}

.mensaje p {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.casosDeEstudioWrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;
}

.casosDeEstudio{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
  border: #0BB1A9 solid;
}


a.casoEstudioButton{
  background-color: #0BB1A9;
  font-size: 10px;
  max-width: 200px;
  color: white;
  border-radius: 5px;
  padding:5px 10px;
  transition: all ease 500ms;
  min-height: 60px;
  display: flex;
  align-items: center;
}

a.casoEstudioButton:hover{
  background-color: #fab226;
  color: white;
}

a.casosEstudioButton{
  background-color: #133F7B;
  font-size: 0.8rem;
  max-width: var(--width-casoEstudioButton, 200px);
  height: 50px;
  color: white;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.casosEstudioButton:hover{
  color: white;
}

h1.tituloCasosEstudio{
  font-size: 1.5rem;
  text-align: left;
  text-transform: none;
  margin-bottom: 0;
}

.casosEstudioSlide{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
  align-items: flex-start;
}

p.indicacion{
  font-size: 0.8rem;
  margin-top: 0;
  margin-left: 1rem;
  text-align: left;
}

.imgCasoEstudio {
  height: 60px;
  margin-top: 0 !important;
  margin-bottom: 20px;
}

.casosEstudioSelection{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-between;
  max-height: 100vh;
  padding: 0 1em;
}

.casoEstudioItem{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.casoEstudioItemTexto{
  display: flex;
  font-size: 0.6rem;
  padding: 5px;
  width: 6vw;
  height: 4vw;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 8px;
}

.seleccionCasosEstudio{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  height: 20svh;
  border: 3px solid #00a399;
  width: 100%;
  column-gap: 10px;
  padding: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  max-height: 120px;
}

.titleCasoEstudio{
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}

.cuerpoCasoEstudio{
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 15px;
}

.botonesCasoEstudio{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
}

.cuerpoTextoCasoEstudio{
  font-size: 0.8rem;
  text-align: justify;
  white-space: pre-line;
}

.footer-mapa{
  display: grid;
  grid-template-columns: 2fr 4fr;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 1rem;
}

.secondCuerpo{
  margin-top: 1em;
}

.secondCuerpo h3{
  font-size: 25px;
  text-align: left;
  font-weight: bold;
}

.secondCuerpo p{
  font-size: 16px;
  text-align: justify;
  width: 70%;
  margin: 0 auto;
  background-color: #c7d0d8;
  border-radius: 15px;
  padding: 1em;
}

@media (max-width: 1440px){
  .casoEstudioItemTexto{
    width: 8vw;
    height: 6vw;
  }
}

@media (max-width: 1024px){
  .casoEstudioItemTexto{
    width: 12vw;
    height: 10vw;
    font-size: 0.8rem;
  }

  a.casoEstudio{
    max-width: 25vw;
    max-height: 20vw;
  }
}

@media (max-width: 768px) {
  .MapaDiagramaInteractivo {
    grid-template-columns: 1fr 1fr;
    height: 450px;
  }

  .MapaDiagramaInteractivo div:nth-child(3) {
    grid-column: 1/3;
  }

  a .numFase {
    width: 60px;
    height: 60px;
    font-size: 25px;
    left: -40px;
  }

  a.subtitulo-1 {
    width: 130px;
    height: 130px;
    font-size: 15px;
  }
  a.encabezado {
    width: 250px;
    height: 250px;
    font-size: 30px;
  }

  .mapa .titulo {
    font-size: 35px;
    padding: 0 1em;
  }

  .mapa .indicacion {
    font-size: 25px;
    text-align: center;
    padding: 0 1em;
  }

  .casoEstudioItemTexto{
    width: 18vw;
    height: 14vw;
    font-size: 0.7rem;
  }

  h1.tituloCasosEstudio{
    font-size: 2rem;
  }

  p.indicacion{
    font-size: 1.5rem;
  }

  a.casoEstudio{
    width: 40%;
    font-size: 0.8rem;
    max-height: auto;
    height: auto;
    padding: auto 0;
  }

  .cuerpoTextoCasoEstudio{
    font-size: 1rem;
  }

  .lineaDiagramaTemaBottom{
    left: 3.3em;
  }

  .footer-mapa{
    grid-template-columns: 1fr;
  }

  .subTemaPrincipalWrapper{
    visibility: hidden;
  }

  .listCuerpo.icon{
    grid-template-columns: 1fr 2fr;
  }

}

@media (max-width: 425px) {
  .mapa {
    margin-top: 5em;
  }

  .imgCasoEstudio {
    display: none;
  }

  .subIndicacion {
    font-size: 2.4rem;
  }

  .MapaDiagramaInteractivo {
    grid-template-columns: 1fr;
    height: 900px;
  }

  .MapaDiagramaInteractivo div:nth-child(3) {
    grid-column: 1;
  }
  a .numFase {
    width: 90px;
    height: 90px;
    font-size: 35px;
    left: -40px;
  }

  a.subtitulo-1 {
    width: 300px;
    height: 300px;
    font-size: 30px;
  }

  a.subtitulo-2 {
    width: 280px;
    height: 280px;
    font-size: 30px;
  }

  a.encabezado {
    width: 450px;
    height: 450px;
    font-size: 50px;
  }

  .encabezados .title {
    font-size: 50px;
  }

  .encabezados .subTitle {
    font-size: 40px;
  }

  .house {
    top: -3em;
  }

  .header {
    grid-template-columns: 1fr;
    padding: 0 1.5em;
    margin-bottom: 1em;
  }

  .cuerpo {
    font-size: 35px;
  }

  .list-cards-1{
    grid-template-columns: 1fr;
  }

  .slide-Diagrama{
    margin-top: 5em;
    max-height: 1500px;
  }

  a.casoEstudioButton{
    font-size: 30px;
    max-width: 100%;
    padding: 1em;
  }
  a.casosEstudioButton{
    
    font-size: 29px;
    min-width: 100%;
    padding: 3em 0;
    margin-top: 1rem;
  }

  .casosDeEstudioWrapper{
    margin-bottom: 5em;
  }

  .casoEstudioItemTexto{
    width: 90vw;
    height: 50vw;
    font-size: 1.5rem;
    padding: 0 1em;
  }

  h1.tituloCasosEstudio{
    font-size: 5rem;
  }

  p.indicacion{
    font-size: 3rem;
    text-align: center;
  }

  .mapa .titulo{
    font-size: 1.2em;
  }

  .mapa .indicacion{
    font-size: 1em;
  }

  .seleccionCasosEstudio{
   height: 100vh;
   max-height: 100vh;
  }

  a.casoEstudio{
    max-width: 100%;
    width: 100%;
    font-size: 1em;
    padding: 1em 0;
    max-height: 50vw;
    height: auto;
  }

  .titleCasoEstudio{
    font-size: 3rem;
  }
  .cuerpoTextoCasoEstudio{
    font-size: 2rem;
    max-height: 100vh;
  }

  .cuerpoCasoEstudio{
    grid-template-columns: 1fr 2fr;
  }

  .cuerpoCasoEstudio button{
    font-size: 1.8rem;
  }
  .house {
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }

  .house svg{
    font-size: 8rem;
  }

  a.casosDeEstudioButton{
    width: 80%;
    height: auto;
    font-size: 1em;
    padding: 1em 0;
  }

  .listCuerpo.icon{
    grid-template-columns: 1fr;
  }

  .header img{
   visibility: hidden;
   width: 0;
  }

  .selector,
  .casoEstudioSlide{
    margin-top: 5em;
  }
}
